// @import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;550;700&display=swap");

// html {
//   font-family: "Public Sans", Roboto, sans-serif;
//   font-size: 62.5%;
// }

*,
*::before,
*::after,
*::placeholder {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1;
  font-size: 1em;
  margin-block-end: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-inline-start: 0;
  resize: none;
  //----------Temporary----------
  position: relative;
  font-family: inherit;
  box-sizing: border-box;
  scroll-behavior: smooth;

  @media screen and (max-width: 601px) {
    font-size: 1em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  font-weight: var(--fw-bold);
  // color: var(--text-color);
  text-transform: capitalize;
}

p,
a {
  line-height: 1.5;
  // color: var(--text-color);
}

a {
  text-decoration: none;
  text-transform: 550;
}

br {
  user-select: all;
}

img,
svg,
button,
a,
li,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// input:-internal-autofill-selected {
//   background-color: transparent !important;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
  -webkit-text-fill-color: black !important;
}

[type="text"] {
  text-transform: capitalize;
}

[type="date"] {
  text-transform: uppercase;
}

[type="password"] {
  text-transform: none;
}

[type="email"] {
  text-transform: lowercase;
}

[type="number"] {
  appearance: textfield;
}

[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}

button,
[type="submit"],
[type="button"],
[type="reset"] {
  font-weight: var(--fw-medium);
}

a,
button,
[type="submit"],
[type="button"],
[type="reset"] {
  cursor: pointer;
}

button,
[type="submit"],
[type="reset"],
textarea,
canvas {
  background-color: transparent;
}

*::placeholder {
  text-transform: unset;
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
