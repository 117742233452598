*:disabled,
.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.EmojiPickerReact {
  position: absolute !important;
  z-index: 1000 !important;
  bottom: -20% !important;
}
