.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: var(--fw-light);
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

body {
  overflow: hidden auto;
  background-color: var(--background);
}

* {
  scroll-behavior: smooth;
  font-family: var(--ff-primary);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  width: 0;
  height: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: var(--primary-shade);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-hover);
}

// *::selection {
//   background-color: var(--primary);
//   color: var(--primary-text);
// }

*::placeholder {
  color: inherit;
  font-weight: 400;
  opacity: 0.6;
  line-height: inherit;
  text-transform: none;
}

.seekButtons {
  transition: 0.15s ease !important;
}

// h1,
h2,
h3,
h4,
h5,
h6 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-hidden {
  width: 0;
  font-size: 0px;
  display: none;
}

//===================MUI======================

.MuiTablePagination-menuItem {
  &,
  * {
    font-size: 1.6rem !important;
  }
}

.MuiAutoComplete-popper {
  border: 1px solid red;
}

//================SHEPHERED===================

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  opacity: 0.2;
}

.shepherd-header {
  display: flex;
  position: absolute;
  padding: 0.8em 1em;
  width: calc(100% - 10px);
  justify-content: flex-end;
}

.shephered-custom-title {
  margin: 0px 0 10px 0;
  width: 90%;
  font-size: 1.05em;
  text-align: justify;
  color: rgb(32, 43, 71);
}

.shephered-custom-foot {
  margin: 15px 0 0 0;
  text-align: left;
  font-weight: var(--fw-light);
  color: #202b47;
}

.shephered-custom-para {
  margin: 0;
  font-size: 1.05em;
  line-height: 20px;
  text-align: justify;
  color: rgb(36, 36, 36);
}

.shephred-theme-custom {
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: 10px 10px 0px 10px;
  background-color: rgba(241, 241, 241, 0.9);
  border-bottom: 8px solid var(--sidebar-tab-active);
}

.shepherd-arrow::before {
  background-color: rgba(241, 241, 241);
}

.shepherd-cancel-icon {
  display: flex;
  height: 25px;
  font-size: 2.5em;
  align-items: center;
  margin-left: 10px !important;
}

.shephred-theme-custom > .shepherd-content > .shepherd-header > button {
  font-weight: var(--fw-light);
}

.shepherd-text {
  font-size: 0.9rem;
}

.shepherd-button,
.shepherd-button-next {
  opacity: 0.8;
  padding: 0.4em 1.2em;
  color: var(--common-text-color);
  background-color: var(--sidebar-tab-active);
}

.shepherd-button-next:hover {
  opacity: 1;
  background-color: var(--sidebar-tab-active) !important;
}

//====================MODALS===================

.verificationModal {
  width: 100vw;
  height: 100vh;
  padding-bottom: 80px;
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 30vw;
    height: auto;
    padding-bottom: 0px;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 30%;
  }
}

.loginModal {
  // width: 100vw;
  width: 100vw;
  height: 100vh;
  padding-bottom: 20px;
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 60%;
    height: auto;
    padding: 10px 0;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 45%;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1701px) {
    /* hi8 */
    width: 35%;
  }
}

.pointsModal {
  display: none;
  @media only screen and (max-width: 1025px) {
    display: block;
    width: auto;
    height: auto;
  }
}

.dialog-modal {
  display: flex;
  width: 320px;
  height: 440px;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  color: var(--text-color);
  box-shadow: var(--shadow3);
  justify-content: flex-start;
  background: var(--second-white);
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 370px;
    height: 450px;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 380px;
  }

  > section {
    padding: 0 18px !important;
  }
}

.feedbackModal {
  width: 100vw;
  height: 100vh;
  padding-bottom: 80px;
  color: var(--text-color);
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: auto;
    height: auto;
    padding-bottom: 0px;
  }

  @media only screen and (min-width: 961px) {
    /* hi4 */
    width: 45%;
    height: auto;
    padding-bottom: 0px;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    min-width: 500px;
    width: auto;
  }
}

.profileUpdateModal {
  width: 100vw;
  height: 100vh;
  padding-bottom: 80px;
  color: var(--text-color);
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 75%;
    height: auto;
    padding-bottom: 0px;
  }
  /* portrait tablets */
  @media only screen and (min-width: 941px) {
    /* hi4 */
    width: 60%;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 45%;
  }
}

.videoUploadModal {
  width: 100vw;
  height: 100vh;
  padding-bottom: 80px;
  color: var(--text-color);
  // background-color: ;
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 80%;
    height: auto;
    padding-bottom: 0px;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 70%;
  }
}

.createCommunityModal {
  width: 100vw;
  height: 100vh;
  padding-bottom: 80px;
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 55%;
    height: auto;
    padding-bottom: 0px;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 40%;
  }
}

.passwordModal {
  width: 100vw;
  height: 100vh;
  padding-bottom: 80px;
  color: var(--text-color);
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 45%;
    height: auto;
    padding-bottom: 0px;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 500px;
    padding-bottom: 40px;
  }
}

.redeemModal {
  color: var(--text-color);
  width: calc(100% - 50px);
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 60%;
  }
  @media only screen and (min-width: 961) {
    /* hi4 */
    width: 45%;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 500px;
  }
}

.home-page-modal {
  width: calc(100% - 50px);
  background-color: var(--white) !important;
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 60%;
  }
  @media only screen and (min-width: 961) {
    /* hi4 */
    width: 45%;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 500px;
  }
}

.orderStatusModal {
  margin: auto 0;
  width: calc(100% - 10px);
  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    margin: 0;
    width: min(55%, 530px);
  }

  @media only screen and (min-width: 961px) {
    width: min(45%, 530px);
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    width: 530px;
  }
}

.policyModal {
  margin: auto 0px;
  width: calc(100% - 20px);
  max-height: 600px !important;

  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 50%;
    max-height: 600px !important;
  }

  @media only screen and (min-width: 961px) {
    /* hi4 */
    width: 45%;
  }

  @media only screen and (min-width: 1281px) {
    /* hi4 */
    width: 45%;
    max-height: 60vh !important;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    width: 500px;
  }
}

.profileModal {
  width: 100vw;
  height: 100vh;
  z-index: 1400;
  padding-top: 0px;
  color: var(--text-color);

  /* for mobile views */
  @media only screen and (min-width: 420px) {
    height: 100vh;
  }

  /* portrait tablets */
  @media only screen and (min-width: 641px) {
    /* hi4 */
    width: 60%;
    height: max-content;
  }
  @media only screen and (min-width: 841px) {
    /* hi4 */
    width: 65%;
  }
  /* when the bottom navigation pops up*/
  @media only screen and (min-width: 1000px) {
    /* hi4 */
    width: 55%;
  }
  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    height: max-content;
    width: 40%;
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
