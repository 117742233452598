.landing .side_drawer-opened,
.landing .side_drawer-closed {
  --background: white;
  --text-color: #222831;
  --border-color: rgba(10, 30, 50, 0.1);
  --card-shadow: 0 0 8px rgba(10, 30, 50, 0.1);
}

:root {
  --fw-normal: 400;
  --fw-light: 500;
  --fw-medium: 550;
  --fw-bold: 700;

  --fs-xxs: 10px;
  --fs-xs: 12px;
  --fs-sm: 14px;
  --fs-md: 16px;
  --fs-lg: 18px;
  --fs-xl: 20px;
  --fs-2xl: 24px;
  --fs-3xl: 30px;
  --fs-4xl: 36px;
  --fs-5xl: 48px;
  --fs-6xl: 60px;

  // Border radius
  --border-radius: 5px;
  // Gap card

  --gap-xl: 30px;
  --gap-lg: 20px;
  --gap-md: 10px;
  --gap-sm: 5px;
  --gap-xsm: 3px;
  --gap-side: 150px;
  --gap-section: 37px;

  --ff-primary: "Inter, sans-serif";
  --ff-secondary: "Source Sans Pro", Roboto, sans-serif;
  --ff-code: "Source Code Pro", Consolas, monospace;
  // === == == == == == == == == === //
  --aqua: rgba(0, 215, 215, 1);
  --aqua-shade: rgba(0, 215, 215, 0.1);
  --aqua-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --blue: rgba(25, 140, 255, 1);
  --blue-shade: rgba(25, 140, 255, 0.1);
  --blue-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --green: rgb(0, 190, 0, 0.8);
  --green-shade: rgba(0, 190, 0, 0.1);
  --green-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --black: rgb(53, 56, 57);
  --black-shade: rgba(8, 16, 16, 0.1);
  --black-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --white: rgba(255, 255, 255, 1);
  --white-shade: rgba(255, 255, 255, 0.1);
  --white-text: rgba(8, 16, 16, 0.95);
  // === == == == == == == == == === //
  --red: rgb(255, 125, 85);
  --red-shade: rgba(255, 60, 0.1);
  --red-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --yellow: rgba(255, 215, 0, 1);
  --yellow-shade: rgba(255, 215, 0, 0.1);
  --yellow-text: rgba(8, 16, 16, 0.95);
  // === == == == == == == == == === //
  --orange: rgba(255, 150, 0, 1);
  --orange-shade: rgba(255, 150, 0, 0.1);
  --orange-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --slateblue: rgba(120, 60, 255, 1);
  --slateblue-shade: rgba(120, 60, 255, 0.1);
  --slateblue-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --violet: rgba(180, 60, 215, 1);
  --violet-shade: rgba(180, 60, 215, 0.1);
  --violet-text: rgba(255, 255, 255, 0.95);
  // === == == == == == == == == === //
  --brand-light: rgb(243, 220, 196);
  --link-color: #5c5cff;
}

.common {
  --menu-back: rgb(241, 241, 241);
  --menu-item: rgb(44, 61, 79);
  --success-color: rgb(44, 218, 148);
  --dark-background: rgb(41, 41, 41);
  --common-text-color: rgb(255, 255, 255);
  --sidebar-tab-active: rgb(243, 145, 46);
  --heat-map-empty: var(--background);
  --menu-hover: rgba(44, 61, 79, 0.2);
  --border: 1px solid rgba(0, 0, 0, 0.2);
  --card-shadow: 0 0 8px var(--select-cont);
  --sidebar-tab-hover: rgb(212, 132, 50, 0.2);
}

//TODO:
.light {
  --background: rgb(255, 255, 255);
  --text-color: rgb(34, 40, 49);
  --background-secondary: rgb(242, 242, 242);

  --text-secondary: rgb(96, 96, 96);
  --profile-cover: rgb(255, 255, 255);
  --text-color2: rgb(44, 61, 79);
  --text-box: rgb(34, 40, 49, 0.1);
  --vd-control-bar: rgb(241, 241, 241);
  --avatar: rgb(243, 145, 46);
  --select-cont: rgba(34, 40, 49, 0.2);
  --progress-color: rgba(34, 40, 49, 0.2);
  //------------------------
  --brand-color: rgb(243, 145, 46);
  --brand-color-text: rgba(255, 255, 255, 0.95);
  --brand-color-shade: rgba(243, 145, 46, 0.1);
  --second-white: rgb(250, 250, 250);

  --border-color: rgb(242, 242, 242);
  --shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.251);
  --card-normal: #fff9f3;
  --card-shadow: 0 0 8px rgba(10, 30, 50, 0.1);
  --card-shadow-hover: 0 5px 16px rgba(10, 30, 50, 0.15);
  --heading: rgba(8, 15, 15, 0.85);
  --sub-heading: rgba(8, 15, 15, 0.7);
  --paragraph: rgba(8, 15, 15, 0.75);
  --second-button: rgba(8, 15, 15, 0.85);
  --second-button-text: rgba(255, 255, 255, 0.95);
  --light-shadow: rgba(0, 0, 0, 0.55);
  --light-shadow2: rgba(0, 0, 0, 0.1);
}

.dark {
  --background: rgb(41, 41, 41);
  // --background: #343434;
  --second-white: rgb(35, 35, 35);
  --background-secondary: rgb(40, 40, 40);

  --text-color: rgb(238, 238, 238);
  --text-secondary: #a1a1a1;
  --text-color2: #f1f1f1;
  --profile-cover: rgba(31, 31, 31, 0.9);
  --text-box: rgba(241, 241, 241, 0.1);
  --vd-control-bar: #2c3d4f;
  --avatar: rgb(212, 132, 50, 0.2);
  --select-cont: rgba(238, 238, 238, 0.2);
  --progress-color: #2c3d4f;
  //-------------------

  --brand-color: #f3912e;
  --brand-color-text: rgba(255, 255, 255, 0.95);
  --brand-color-shade: rgba(243, 145, 46, 0.1);
  --border-color: rgba(255, 255, 255, 0.1);
  /* --shadow: 0 0 12px rgba(255, 255, 255, 0.15); */
  --shadow: 0 0 4px rgba(255, 255, 255, 0.2);
  /* --shadow: 0 0 20px rgba(255, 255, 255, 0.25); */
  --card-normal: #fff9f3;
  /* --card-shadow: 0 0 8px rgba(255, 255, 255, 0.1); */
  /* --card-shadow-hover: 0 5px 16px rgba(255, 255, 255, 0.15); */
  --heading: rgba(255, 255, 255, 0.85);
  --sub-heading: rgba(255, 255, 255, 0.7);
  --paragraph: rgba(255, 255, 255, 0.75);
  --second-button: rgba(255, 255, 255, 0.95);
  --second-button-text: rgba(8, 15, 15, 0.95);
  --light-shadow: rgba(147, 147, 147, 0.35);
  --light-shadow2: rgba(147, 147, 147, 0.2);
}
