.main {
  display: flex;
  min-height: 100vh;
  background-color: var(--second-white);
}

.uploadSideBar {
  width: 280px;
}

.content {
  max-width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  overflow: hidden;
  min-height: 100vh;
  padding: 48px 0 65px;
  // max-height: calc(100vh - 60px);

  @media (min-width: 961px) {
    padding: 60px 0 0 66px;
  }
}

.upload-section {
  width: 80%;
}

.contents {
  max-width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  min-height: 100vh;
  overflow: hidden;
  padding: 25px 0 65px;
  @media (min-width: 961px) {
    padding: 0px 0 0 0px;
  }
}
.batch-content {
  max-width: 100%;
  flex: 1 1 auto;
  min-height: 100vh;
  overflow: hidden;
  padding: 60px 0 65px;
  @media (min-width: 961px) {
    padding: 60px 0 0 0;
  }
}

.video-content {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  padding: 0 10px;
  overflow: hidden;
  padding: 60px 10px 65px 10px;
  @media screen and (min-width: 481px) {
    display: flex;
  }
  @media screen and (min-width: 961px) {
    padding: 60px 0 0 60px;
  }

  .player-side {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .no-video {
    display: flex;
    gap: 20px;
    width: 100%;
    height: auto;
    padding: 50px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 961px) {
      height: calc(100vh - 65px - 60px);
    }

    h1 {
      font-weight: var(--fw-medium);
      font-size: 23px;
      text-align: center;
      text-transform: none;
      -webkit-line-clamp: 2;
      span {
        color: var(--brand-color);
      }
      @media screen and (min-width: 641px) {
        font-size: 25px;
      }

      @media screen and (min-width: 1025px) {
        font-size: 28px;
      }

      @media screen and (min-width: 1281px) {
        font-size: 32px;
      }
    }

    article {
      display: flex;
      width: 100%;
      .left {
        width: 100%;
        display: none;
        align-items: center;
        justify-content: center;

        img {
          width: min(80%, 440px);
          display: none;
          @media screen and (min-width: 961px) {
            display: block;
            width: min(90%, 460px);
          }

          @media screen and (min-width: 1025px) {
            width: min(80%, 580px);
          }
        }
        @media screen and (min-width: 961px) {
          display: flex;
        }
      }

      .right {
        gap: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h2 {
          width: 95%;
          font-weight: var(--fw-medium);
          font-size: 17px;
          text-align: center;
          text-transform: none;
          -webkit-line-clamp: 2;
          @media screen and (min-width: 641px) {
            font-size: 18px;
          }

          @media screen and (min-width: 1025px) {
            font-size: 20px;
          }

          @media screen and (min-width: 1281px) {
            font-size: 23px;
          }
        }

        p {
          width: 90%;
          text-align: center;
        }

        .btn {
          display: flex;
          gap: 5px;
          margin: 0 auto;
          margin-top: 10px;
          padding: 15px 20px;
          border-radius: var(--border-radius);
          align-items: center;
          color: var(--black);
          text-transform: none;
          font-size: var(--fs-text);
          background-color: var(--success-color);

          &:hover {
            opacity: 0.8;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .smallPlayer {
          width: 400px;
          height: auto;
          @media screen and (min-width: 641px) {
            width: 80%;
            height: auto;
          }
          @media screen and (min-width: 1025px) {
            height: 240px;
            aspect-ratio: 16 /9;
          }
          @media screen and (min-width: 1281px) {
            width: auto;
            height: 340px;
          }
        }
      }

      @media screen and (min-width: 961px) {
        flex-direction: row;
        // height: calc(100vh - 65px);

        .left {
          width: 45%;
        }
        .right {
          width: 50%;
        }
      }

      @media screen and (min-width: 1281px) {
        .left {
          width: 50%;
        }
        .right {
          width: 50%;
        }
      }
      @media screen and (min-width: 1481px) {
        .left {
          width: 60%;
        }
        .right {
          width: 40%;
        }
      }
    }
  }
}

.container {
  display: flex;
  width: 100%;
  overflow: auto;
  margin-top: 110px;
  height: calc(100vh - 65px - 110px);
}
.container-upload {
  width: 100%;
  overflow: auto;
  margin-top: 60px;
  height: calc(100vh - 65px);
  .outlet {
    height: 100%;
    overflow: auto;
  }
}

.nav {
  display: flex;
  left: 0;
  width: 100%;
  bottom: -2px;
  height: 60px;
  z-index: 1299;
  position: fixed;

  @media (min-width: 961px) {
    display: none !important;
  }
}

.landing-sidebar {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.general-sidebar {
  color: var(--text-color) !important;
  background-color: var(--background) !important;
}
